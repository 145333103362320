import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql, PageProps } from "gatsby";
import Layout from "../components/layout";
import { h1 } from "../base/typography.css";

type TagsProps = {
  site: {
    siteMetadata: {
      title: string;
    }
  };
  allMarkdownRemark: {
    totalCount: number;
    edges: {
      node: {
        frontmatter: {
          title: string;
        };
        fields: {
          slug: string;
        };
      }
    }[];
  }
};

type TagsPageContext = {
  tag: string;
}

type TagsPageProps = PageProps<TagsProps, TagsPageContext>;

const Tags = ({ pageContext, data, location }: TagsPageProps) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `Posts tagged "${tag}"`
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <h1 className={h1}>{tagHeader}</h1>
      <ul>
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title } = node.frontmatter
          return (
            <li key={slug}>
              <Link to={slug}>{title}</Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
